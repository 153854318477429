import { Formik, FormikProps } from "formik";
import { useRef } from "react";
import yup, { object, string } from "yup";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import useViewLog from "../../hooks/use-view-log";
import Button from "../default/button";
import Notification from "../default/notifications";
import TextArea from "../default/text-area";
import TextField from "../default/text-field";

interface ValuesF {
  nome: string;
  telefone: string;
  provincia: string;
  mensagem: string;
}

export const mensagemSchema: yup.Schema = object({
  nome: string()
    .matches(/^(\S+\s+\S+.+)$/, "Insira pelo menos dois nomes.")
    .required("Insira o seu nome."),
  telefone: string()
    .required("Insira numero de telefone.")
    .matches(/^8[2-7]\d{7}$/, "Numero de telefone inválido."),
  provincia: string().required("insira sua mensagem"),
  mensagem: string().required("insira sua mensagem"),
});

const defaultValues = {
  nome: "",
  telefone: "",
  mensagem: "",
  provincia: "",
} as ValuesF;

const FormContactUs = () => {
  const { viewLog, setLoading, setNewLog } = useViewLog();

  const handleSendMessage = (dados: any, listenerSuccess?: () => void) => {
    setLoading();
    console.log(dados);

    api.post(
      {
        path: "/api/resources/contactus",
        data: {
          nome: dados.nome,
          telefone: dados.telefone,
          provincia: dados.provincia,
          mensagem: dados.mensagem,
        },
      },
      (data) => {
        setNewLog({
          type: "SUCCESS",
          message:
            "A sua mensagem foi enviada com sucesso a IziCar Motors. Obrigado pelo contacto",
        });
        listenerSuccess?.();
      },
      (error) => {
        if (error.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão, tente novamente.",
          });
        } else {
          setNewLog({
            type: "ERROR",
            message:
              "Ocorreu erro inesperado, tente novamente caso o erro persista contacte-nos pelo email : info@izicarmotors.co.mz.",
          });
        }
      },
    );
  };

  const formRef = useRef<FormikProps<ValuesF>>(null);

  return (
    <div>
      <div className=" flex flex-col">
        <span className=" text-center text-blue-700 font-semibold text-[16px]">
          Contacte-nos
        </span>
        <span className=" text-black font-bold text-[25px] text-center py-[25px]">
          Não hesite em contactar-nos!
        </span>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={defaultValues}
        onSubmit={(values) => {
          handleSendMessage(values as ValuesF, () => {
            if (formRef.current) {
              formRef.current.resetForm?.();
            }
          });
        }}
        validationSchema={mensagemSchema}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => {
          return (
            <div className="flex flex-col gap-[20px]">
              <div className="w-full flex flex-1 flex-col gap-[20px] lg:flex-row">
                <div className="flex-1">
                  <TextField
                    label="Nome Completo *"
                    value={values.nome}
                    setFieldValue={setFieldValue}
                    field="nome"
                    errorMessage={errors.nome}
                  />
                </div>
                <div className="flex-1">
                  <TextField
                    label="Telefone *"
                    value={values.telefone}
                    field="telefone"
                    errorMessage={errors.telefone}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <TextField
                label="Localização *"
                value={values.provincia}
                setFieldValue={setFieldValue}
                field="provincia"
                errorMessage={errors.provincia}
              />
              <TextArea
                label="Mensagem *"
                rows={3}
                multiline={true}
                value={values.mensagem}
                setFieldValue={setFieldValue}
                field="mensagem"
                errorMessage={errors.mensagem}
              />
              {viewLog.show && (
                <div>
                  <Notification message={viewLog.message} type={viewLog.type} />
                </div>
              )}
              <Button
                onClick={() => {
                  if (Object.values(errors).length > 0) {
                    setNewLog({
                      message:
                        "Certifique de que preencheu todos campos obrigátorios(*).",
                      type: "WARNING",
                    });
                    return;
                  }
                  handleSubmit();
                }}
                title="ENVIAR MENSAGEM"
                isLoading={viewLog.isLoading}
              ></Button>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormContactUs;
