import Container from "../components/default/container";

const PageAbout = () => {
  const data = [
    {
      title: "Coleta de Informações Pessoais",
      description:
        "Nossa empresa coleta informações pessoais de nossos clientes para assegurar a melhor experiência no processo de compra e aluguer de veículos. As informações que coletamos incluem, mas não estão limitadas a: nome completo, endereço residencial, endereço de e-mail, número de telefone, detalhes da carteira de motorista, documento de identificação e informações financeiras necessárias para processar o pagamento. Essas informações são essenciais para garantir a autenticidade da reserva e para atender aos requisitos legais e contratuais.",
    },
    {
      title: "Uso de Informações Pessoais",
      description:
        "As informações pessoais que coletamos são utilizadas exclusivamente para fornecer nossos serviços de venda e aluguer de veículos. Isso inclui a administração de reservas, confirmação de contratos de venda ou aluguer, comunicação sobre o status do aluguer, e para garantir que todos os requisitos necessários para eventos como casamentos e outros tipos de celebrações sejam atendidos. Também usamos essas informações para aprimorar nossos serviços, como personalização de ofertas e recomendações de veículos, sempre visando uma experiência excepcional ao cliente.",
    },
    {
      title: "Compartilhamento de Dados com Terceiros",
      description:
        "Nós não compartilhamos suas informações pessoais com terceiros, a menos que seja absolutamente necessário para a prestação dos nossos serviços. Por exemplo, seus dados poderão ser compartilhados com parceiros de pagamento, empresas de seguros, ou agências governamentais, caso isso seja necessário para o cumprimento de requisitos legais ou para processar um contrato de compra ou aluguer. Garantimos que qualquer parceiro com quem compartilhamos dados adere aos mesmos níveis de privacidade e segurança que nossa empresa estabelece. Não vendemos ou alugamos seus dados para fins comerciais.",
    },
    {
      title: "Segurança de Dados",
      description:
        "Levamos a segurança de seus dados muito a sério e implementamos medidas de proteção rigorosas para salvaguardar todas as informações pessoais que coletamos. Utilizamos criptografia avançada em todas as nossas plataformas digitais, assim como métodos de autenticação para garantir que apenas pessoal autorizado tenha acesso às informações. Revisamos regularmente nossos protocolos de segurança para manter um alto nível de proteção contra acessos não autorizados, fraudes, ou qualquer tipo de violação. Sua privacidade é uma prioridade para nós, e fazemos todo o possível para garantir que seus dados estejam seguros.",
    },
    {
      title: "Cookies e Tecnologias de Rastreamento",
      description:
        "Nosso site e aplicativos utilizam cookies e outras tecnologias de rastreamento para melhorar a experiência de navegação e fornecer um serviço mais personalizado. Os cookies nos permitem entender melhor suas preferências e comportamentos, permitindo que apresentemos conteúdo e ofertas relevantes, além de melhorar a funcionalidade do nosso site. Você pode optar por desativar os cookies em seu navegador, no entanto, isso pode afetar algumas funcionalidades do site. Além disso, usamos ferramentas de análise para monitorar o uso do site e identificar áreas de melhoria.",
    },
    {
      title: "Direitos dos Usuários",
      description:
        "Você tem o direito de acessar, corrigir ou solicitar a exclusão de seus dados pessoais a qualquer momento. Caso deseje revisar as informações que coletamos sobre você ou solicitar a remoção de seus dados do nosso sistema, entre em contato conosco por meio de nossos canais de atendimento. Também respeitamos o direito de restringir o processamento de seus dados ou de solicitar a portabilidade dos mesmos para outro fornecedor de serviços, de acordo com as regulamentações de proteção de dados vigentes.",
    },
  ];

  return (
    <Container>
      <div className=" flex flex-col mt-[40px] gap-[20px] xl:px-[80px] xl:mt-[80px]">
        <div>
          <p className=" text-xl font-bold text-blue-500 pl-[40px]">
            Sobre Nós | IziCar Motors - Conectando Paixões Automotivas
          </p>
          <div className="py-[20px] flex flex-col gap-[15px]">
            <p className=" text-justify">
              Bem-vindo à{" "}
              <span className=" text-blue-700 font-bold">IziCar Motors</span>, a
              sua parceira de confiança na compra, venda e aluguer de viaturas
              para todos os momentos importantes da sua vida. Com uma vasta
              experiência no setor automotivo, nos especializamos em oferecer
              soluções completas para quem procura veículos de qualidade, seja
              para uso pessoal, empresarial ou eventos especiais.
            </p>
            <p className=" text-justify">
              Nosso portfólio de serviços inclui a{" "}
              <span className="text-blue-700 font-bold">
                venda de viaturas novas e usadas
              </span>
              , cuidadosamente selecionadas para garantir o mais alto nível de
              satisfação aos nossos clientes. Cada veículo é submetido a
              rigorosos processos de inspeção e manutenção para assegurar sua
              funcionalidade e segurança antes de ser oferecido para compra.
            </p>
            <p className=" text-justify">
              Além disso, somos especialistas em{" "}
              <span className="text-blue-700 font-bold">
                aluguer de veículos
              </span>
              , com uma ampla gama de opções, desde carros compactos até
              veículos de luxo, todos equipados para atender às suas
              necessidades. Seja para uma ocasião especial, como{" "}
              <span className="text-blue-700 font-bold">
                casamentos, festas, eventos corporativos, ou para atender à sua
                necessidade diária de transporte
              </span>
              , estamos prontos para fornecer a solução ideal.
            </p>
          </div>
        </div>
        <p className=" text-xl font-bold text-blue-500 pl-[40px]">
          IziCar Motors - Políticas e Privacidade
        </p>
        {data.map((item) => {
          return (
            <LabelInfo title={item.title} description={item.description} />
          );
        })}
      </div>
    </Container>
  );
};

interface LabelInfoProps {
  title: String;
  description: String;
}

const LabelInfo = ({ title, description }: LabelInfoProps) => {
  return (
    <div className="w-full border-[1px] border-blue-700 rounded-[15px] overflow-hidden">
      <div className="w-full bg-blue-900 px-[30px]">
        <p className="px-[10px] py-[10px] font-bold text-blue-500">{title}</p>
      </div>
      <div className="py-[15px]">
        <p className="px-[30px] text-justify">{description}</p>
      </div>
    </div>
  );
};

export default PageAbout;
