import Images from "../../assets/img";
import { IcDone } from "../../assets/svg";

export const SectionAbout = () => {
  return (
    <div className="flex flex-col">
      <div className="mt-[50px]">
        <p className=" text-blue-700 text-[16px] font-bold text-center">
          IziCar Motors
        </p>
        <p className=" text-2xl font-bold text-center mb-[30px]">
          Porquê Buscar Meu Carro dos Sonhos na IziCar
        </p>
      </div>
      <div className="flex flex-wrap mb-20 lg:mt-[30px] lg:gap-10 lg:flex-nowrap ">
        <div
          className={`flex items-center justify-center w-full lg:w-1/2 ${
            "right" === "right" ? "lg:order-1" : ""
          }`}
        >
          <div>
            <img
              src={Images.about}
              width={521}
              height={521}
              alt="Benefits"
              className={"object-cover"}
            />
          </div>
        </div>

        <div
          className={`flex flex-wrap items-center w-full lg:w-1/2 ${
            "right" === "right" ? "lg:justify-end" : ""
          }`}
        >
          <div>
            <div className="flex flex-col w-full mt-4">
              <h3 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-gray-800 lg:leading-tight lg:text-4xl dark:text-white">
                {
                  "A iziCar Motors tem uma interface fácil e amigável para uma experiência simplificada."
                }
              </h3>
            </div>

            <div className="w-full mt-5 flex flex-col gap-[15px]">
              <div className="flex flex-row items-center">
                <IcDone width={18} height={18} />
                <span className="ml-[10px] text-gray-500">
                  Design intuitivo para uma interação sem esforço
                </span>
              </div>
              <div className="flex flex-row items-center">
                <IcDone width={18} height={18} />
                <span className="ml-[10px] text-gray-500">
                  Exibição detalhada das informações dos veículos
                </span>
              </div>
              <div className="flex flex-row items-center">
                <IcDone width={18} height={18} />
                <span className="ml-[10px] text-gray-500">
                  Funcionalidade de contato direto com o vendedor
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
