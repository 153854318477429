import { ReactNode } from "react";
import { Footer } from "../organisms/footer";
import { Header } from "../organisms/header";

interface ContainerProps {
  children: ReactNode;
}

export default function Container({ children }: ContainerProps) {
  return (
    <div className="px-[25px] lg:px-[80px] xl:px-[120px] py-[10px]">
      <div className="mt-[30px]">
        <Header />
      </div>
      {children}
      <Footer />
    </div>
  );
}
