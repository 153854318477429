"use client";
import { Modal } from "@mui/material";
import { NavLink } from "react-router-dom";
import { IcClose, IcMenu } from "../../assets/svg";
import { useBetterState } from "../../hooks/use-better-state";
import Button from "../default/button";
import logo from "./../../assets/img/logo.png";

export const Header = () => {
  const showMenu = useBetterState(false);

  const toggleMenu = () => {
    showMenu.value = !showMenu.value;
  };

  const showUnvailable = useBetterState(false);

  return (
    <div className="w-full transition-all">
      <Modal open={showUnvailable.value}>
        <div className="w-full h-full flex justify-center items-center">
          <div className=" bg-[#FFF] w-[300px] ld:w-[350px] min-h-[150px] py-[40px] rounded-[15px] px-[20px] flex justify-center items-center flex-col gap-[20px]">
            <p className=" font-bold text-[18px] lg:text-[23px]  text-center">
              Recurso indisponivél no momento!
            </p>
            <div className="w-full flex-1">
              <Button
                onClick={() => {
                  showUnvailable.value = false;
                }}
                title="CERTO"
              ></Button>
            </div>
          </div>
        </div>
      </Modal>
      <nav className="flex flex-col">
        {/* Logo  */}
        <div className="flex flex-row justify-between items-center">
          <NavLink to="/">
            <div className="flex items-center space-x-4 text-2xl font-medium text-[#054A91]">
              <div>
                <img src={logo} width={60} alt="N" height={50} />
              </div>
              <p className=" font-bold">IziCar Motors</p>
            </div>
          </NavLink>
          <div className="hidden lg:flex">
            <MenuContainer />
          </div>
          <div className="hidden mr-3 lg:flex">
            <NavLink
              to="#"
              className=""
              onClick={() => {
                console.log("Teste");
                showUnvailable.value = true;
              }}
            >
              <div className="w-full py-[8px] rounded-[5px] px-[30px] bg-gradient-to-br from-blue-500 to-blue-800">
                <p className="text-center text-[16px] text-black-50">ENTRAR</p>
              </div>
            </NavLink>
          </div>
          <div className="lg:hidden">
            <button onClick={toggleMenu}>
              {!showMenu.value ? (
                <div className=" hover:bg-blue-900 px-[5px] py-[5px] rounded-[4px] transition-all">
                  <IcMenu width={25} height={25} />
                </div>
              ) : (
                <div className=" hover:bg-blue-900 px-[5px] py-[5px] rounded-[4px] transition-all">
                  <IcClose width={25} height={25} />
                </div>
              )}
            </button>
          </div>
        </div>
        {showMenu.value && (
          <div className="flex lg:hidden transition-all">
            <MenuContainer
              onEnter={() => {
                showUnvailable.value = true;
              }}
            />
          </div>
        )}
      </nav>
    </div>
  );
};

interface MenuContainerProps {
  onEnter?: () => void;
}

function MenuContainer({ onEnter }: MenuContainerProps) {
  const navigation = [
    {
      label: "INICIO",
      link: "/",
    },
    {
      label: "SOBRE NÓS",
      link: "/about",
    },
  ];

  return (
    <div className="w-full flex flex-col gap-[15px] mt-[20px] lg:items-center">
      <ul className="items-center justify-end flex-1 list-none flex-col lg:flex lg:flex-row lg:gap-[40px]">
        {navigation.map((menu, index) => (
          <li className="mr-3" key={index}>
            <NavLink
              to={menu.link}
              className="inline-block text-lg font-normal text-gray-800 no-underline rounded-md dark:text-gray-200 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-gray-800 hover:scale-110 transition-all"
            >
              <p className="text-[16px] text-black-700 font-semibold hover:text-[#000ed1]">
                {menu.label}
              </p>
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="w-full block lg:hidden">
        <div className="w-full">
          <NavLink to="#" className="" onClick={onEnter}>
            <div className="w-full bg-blue-500 py-[8px] rounded-[5px] bg-gradient-to-br from-blue-500 to-blue-800">
              <p className="text-center text-[16px] text-black-100">ENTRAR</p>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
