import about from "./about.png";
import banner from "./banner.png";
import logo from "./logo.png";

const Images = {
  banner,
  about,
  logo,
};

export default Images;
