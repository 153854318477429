import CircularProgress from "@mui/material/CircularProgress";

interface ButtonProps {
  title?: string;
  onClick?: () => void;
  isLoading?: boolean;
}

const Button = ({ title, onClick, isLoading = false }: ButtonProps) => {
  return (
    <button onClick={onClick} style={{ width: "100%" }}>
      <div className="w-full py-[8px] h-[45px] flex justify-center items-center rounded-[5px] px-[30px] bg-gradient-to-br from-blue-500 to-blue-800">
        {isLoading ? (
          <CircularProgress
            sx={{
              color: "#FFF",
            }}
            size={22}
          />
        ) : (
          <p className="text-center text-[16px] text-black-50">{title}</p>
        )}
      </div>
    </button>
  );
};

export default Button;
