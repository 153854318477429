const Funcionalidades = () => {
  const recursos = [
    {
      icon: "AR",
      title: "Alta Resolução",
      description:
        "Explore uma aplicação com resolução excepcional, proporcionando uma experiência visual incrivelmente detalhada e envolvente.",
    },
    {
      icon: "PR",
      title: "Preparado para Retina",
      description:
        "Nossa aplicação otimizada para Retina oferece detalhes envolventes e uma experiência visual impressionante.",
    },
    {
      icon: "DE",
      title: "Dados Editáveis",
      description:
        "Experiência visual de alta qualidade com dados totalmente editáveis para máxima flexibilidade.",
    },
    {
      icon: "TS",
      title: "Totalmente Seguro",
      description: "Nossa aplicação mantém seus dados totalmente protegidos.",
    },
    {
      icon: "AN",
      title: "Armazenamento em Nuvem",
      description:
        "Dados seguros, armazenamento em nuvem tudo em uma aplicação.",
    },
    {
      icon: "TR",
      title: "Totalmente Responsiva",
      description:
        "Experiência adaptável em qualquer dispositivo, nossa aplicação é totalmente responsiva.",
    },
  ];

  return (
    <div>
      <div className=" flex flex-col mt-[40px] lg:mt-[60px] ">
        <span className=" text-center text-blue-700 font-semibold text-[16px]">
          Funcionalidades e Recursos do Aplicativo
        </span>
        <span className=" text-black font-bold text-[25px] text-center py-[25px]">
          Funcionalidades Inscrivéis
        </span>
      </div>
      <div className=" gap-[20px] grid md:grid-cols-2 lg:grid-cols-3">
        {recursos.map((item, index) => {
          return (
            <div
              key={index}
              className="bg-[#F0F6FF] p-[20px] rounded-[15px] flex flex-col gap-[15px] transition-all duration-500 hover:bg-[#C7D8EC] hover:scale-105"
            >
              <div className="flex bg-gradient-to-br from-[#215F9E] to-[#DFE8F1] w-[60px] h-[60px] rounded-[100px] justify-center items-center">
                <span className=" font-extrabold">{item.icon}</span>
              </div>
              <h6 className=" text-[#262B47] font-normal text-[21px]">
                {item.title}
              </h6>
              <p className=" text-[#79818A] text-[18px]">{item.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Funcionalidades;
