import { Alert } from "@mui/material";
import { ViewLogProps } from "../../hooks/use-view-log";

const Notification = ({ type, message }: Omit<ViewLogProps, "show">) => {
  return (
    <div className="flex flex-1 w-full justify-center items-center">
      {type === "SUCCESS" ? (
        <Alert severity="success">{message}</Alert>
      ) : type === "WARNING" ? (
        <Alert severity="warning">{message}</Alert>
      ) : (
        <Alert severity="error">{message}</Alert>
      )}
    </div>
  );
};

export default Notification;
