import { NavLink } from "react-router-dom";
import Images from "../../assets/img";

const BannerMain = () => {
  return (
    <div className="flex flex-wrap mt-[40px]">
      <div className="flex items-center justify-center w-full lg:w-1/2">
        <div className="max-w-2xl my-[26px]">
          <p className="text-4xl font-bold leading-snug tracking-tight text-black-700 lg:text-4xl lg:leading-tight xl:text-6xl xl:leading-tight dark:text-white">
            IziCar Motors Conectando Paixões Automotivas
          </p>
          <p className="py-5 text-xl leading-normal text-gray-500 lg:text-xl xl:text-2xl dark:text-gray-300">
            Bem-vindo à IziCar Motors, sua parceira confiável no universo
            automotivo. Somos uma empresa apaixonada por carros, comprometida em
            proporcionar experiências excepcionais de compra e venda. Temos um
            universo de carros disponivéis na nossa aplicação.
          </p>
          <div className="flex flex-col items-start space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row">
            <NavLink
              to={
                "https://play.google.com/store/apps/details?id=com.sf.izicarmotors"
              }
            >
              <div className="w-[350px] py-[14px] rounded-[5px] px-[30px] bg-gradient-to-br from-blue-500 to-blue-800">
                <p className="text-center text-[23px] font-bold text-black-50">
                  Baixar na Playstore
                </p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full lg:w-1/2">
        <div className="">
          <img
            src={Images.banner}
            width="616"
            height="617"
            className={"object-cover"}
            alt="Hero Illustration"
            loading="eager"
          />
        </div>
      </div>
    </div>
  );
};

export default BannerMain;
