import Container from "../default/container";
import { SectionAbout } from "./about-app";
import BannerMain from "./banner";
import FormContactUs from "./contact-us";
import Funcionalidades from "./funcionalidades";

export default function LandingPage() {
  return (
    <Container>
      <BannerMain />
      <div className="mt-[40px]">
        <Funcionalidades />
      </div>
      <div className=" mt-[20px] xl:mt-[60px]">
        <SectionAbout />
      </div>
      <div>
        <FormContactUs />
      </div>
    </Container>
  );
}
